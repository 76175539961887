import React from 'react';
import BeatingHeart from '../BeatingHeart';

import Marker from '../../../assets/images/integrations/marker.svg';

function Github() {
  return (
    <BeatingHeart>
      <Marker width={170} height={140} />
    </BeatingHeart>
  );
}

export default Github;
