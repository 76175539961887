import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import MarkerBeatingHeart from '../../components/integrations/MarkerBeatingHeart';
import Link from '../../components/link';
import Layout from '../../layout';

const styles = css`
  .items {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4rem;
    max-width: 54rem;
    flex-wrap: wrap;
    margin: 0 auto 5rem;
    padding: 0 1rem;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;

    text-align: center;
    width: 24rem;
    & svg {
      border-radius: 100px;
      background: #107db522;
      padding: 10px;
      color: #107db5;
    }
  }

  .buttonContainer {
    text-align: center;
  }
  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 2rem;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin: 2rem auto;

    & span {
      position: relative;
      z-index: 1;
    }
    & svg {
      vertical-align: text-bottom;
      margin-left: 0.5rem;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Marker.io integration" keywords={['']} pathname={this.props.location.pathname} />

        <PageHeader>
          <MarkerBeatingHeart />
          <h1>Report issues anywhere using Marker.io directly from inside Polypane</h1>

          <Link to="/integrations/">See all integrations</Link>
        </PageHeader>

        <div className={styles.items}>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-photo"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="15" y1="8" x2="15.01" y2="8" />
              <rect x="4" y="4" width="16" height="16" rx="3" />
              <path d="M4 15l4 -4a3 5 0 0 1 3 0l5 5" />
              <path d="M14 14l1 -1a3 5 0 0 1 3 0l2 2" />
            </svg>
            <h2>Capture screenshots</h2>
            <p>Capture screenshots inside Polypane and send them to Marker.io with all data.</p>
          </div>

          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-edit-circle"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 15l8.385 -8.415a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3z" />
              <path d="M16 5l3 3" />
              <path d="M9 7.07a7.002 7.002 0 0 0 1 13.93a7.002 7.002 0 0 0 6.929 -5.999" />
            </svg>

            <h2>Annotate your screenshots</h2>
            <p>Add text, arrows and shapes to your screenshots to explain issues.</p>
          </div>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-send"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1="10" y1="14" x2="21" y2="3" />
              <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
            </svg>
            <h2>Send bug reports anywhere</h2>
            <p>Send your captures to Jira, Asana, Github and dozens more integrations.</p>
          </div>
          <div className={styles.item}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-layers-linked"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M19 8.268a2 2 0 0 1 1 1.732v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h3" />
              <path d="M5.003 15.734a2 2 0 0 1 -1.003 -1.734v-8a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-3" />
            </svg>
            <h2>Metadata included</h2>
            <p>Technical data like browser info and console messages is added to your reports automatically.</p>
          </div>
        </div>
        <div className="page childPadding">
          <p className={styles.buttonContainer}>
            <a className={styles.primaryButton} href="https://marker.io/?utm_source=polypane/">
              <span>
                Get Marker.io
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-external-link"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#ffffff"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                  <line x1="10" y1="14" x2="20" y2="4" />
                  <polyline points="15 4 20 4 20 9" />
                </svg>
              </span>
            </a>
          </p>
          <h2 id="setup">Setup instructions</h2>
          <p>How to use Marker.io in Polypane.</p>

          <h3>Step 1: Authenticate with Marker.io.</h3>
          <p>
            The Integrations popover is visible above each Pane in Polypane by clicking the
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-puzzle"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ verticalAlign: 'middle', margin: '0 0.25rem' }}
            >
              <path d="M19 6.84a2.007 2.007 0 0 1 1 1.754v6.555c0 .728 -.394 1.4 -1.03 1.753l-6 3.844a1.995 1.995 0 0 1 -1.94 0l-6 -3.844a2.006 2.006 0 0 1 -1.03 -1.752v-6.557c0 -.728 .394 -1.399 1.03 -1.753l6 -3.582a2.049 2.049 0 0 1 2 0l6 3.582h-.03z" />
              <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
            </svg>
            icon. Then click "Select new destination" in the popover.</p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker1.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 2: Log in to Marker.io.</h3>
          <p>Enter your e-mail address to log in to Marker.io</p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker2.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 3: Fill in the Login code Marker.io sent you.</h3>
          <p>
            Marker.io sends you a login code for Polypane through your e-mail. Copy it from your email and click 'Log in
            now'.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker3.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 4: Connect a destination.</h3>
          <p>
            From the dropdown, select the destination you want to use in Polypane. Don't worry, you can always change
            this later.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker4.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 5: Go back to Polypane.</h3>
          <p>
            Click the "Go back to Polypane" button to send the info from Marker.io to Polypane. If you close the window
            instead, go back to step 1.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker5.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 6: Activate Marker.io!</h3>
          <p>
            You can now see your selected destination inside Polypane (with icon). Click the "Active" checkbox to turn
            Marker.io on for this pane.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker6.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 7: Capture entire screen or area.</h3>
          <p>
            Use Marker.io to add a comment to the page. This will be visible to all your team members, and you can read
            their replies directly in Polypane too!
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker7.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <h3>Step 8: Send capture to Marker.io.</h3>
          <p>
            From here you can annotate the capture and send it to the destination you chose in step 4. Depending on the
            destination you can also add different properties or fields.
          </p>

          <p>
            When you're done, you can open the Integrations popover and uncheck the Active checkbox to deactivate
            Marker.io.
          </p>
        </div>
        <StaticImage
          src="../../../assets/images/integrations/screens/markerio/marker8.png"
          alt=""
          className="ig-imgshadow"
        />
        <div className="page childPadding">
          <p className={styles.buttonContainer}>
            <Link to="/integrations/">Back to integrations overview</Link>
          </p>
        </div>
        <CTA />
      </Layout>
    );
  }
}

export default Page;
